@font-face { font-family: Neue Haas Grotesk Display; src: url("fonts/NeueHaasDisplayRoman.ttf"); }
@font-face { font-family: Comic Sans; src: url("fonts/comic.ttf"); }


:root{
  --font-display: "Neue Haas Grotesk Display";

  --main-color: #f2fffe;
  --active-color: #7dd8c0;
  --inactive-color: #e4765b;

  --ice-blue: #97f9f9;
  --uranian-blue: #a4def9;
  --columbia-blue: #c1e0f7;
  --thistle: #cfbae1;
  --lilac: #c59fc9;
}

* {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;

  /* VARIABLES */
  font-family: var(--font-display);
}

html{
  background-color: #141414;
  color: var(--main-color);
}

.app {
  display: flex;
  min-height: calc(100vh - 1.9rem - 7.5rem - 7.5rem);
}

.main {
  padding: 1rem;
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 80%;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.gradient-spin {
  animation: 2s rotate linear infinite;
}

.gradient-spin:hover {
  background-image: conic-gradient(
    from var(--angle) at 0% 0%,
    var(--ice-blue),
    var(--uranian-blue),
    var(--columbia-blue),
    var(--thistle),
    var(--lilac),
    var(--ice-blue)
  );
  
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

@keyframes rotate{
  to{
    --angle: 360deg;
  }
}

.comic-sans{
  font-family: Comic Sans;
  transition: all 0.15s ease-in;
}

.active{
  color: var(--active-color);
  text-shadow: var(--active-color) 0px 0px 5px 0.5;
}

.inactive{
  color: var(--inactive-color);
  text-shadow: var(--inactive-color) 0px 0px 5px 0.5;
}

.head{
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  padding-left: 4rem;
  outline: 1px solid #fff2;
  box-shadow: 0px 5px 6px #0008;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: #141414;

  justify-content: flex-start;
}

.head h1{
  
}

.head em{
  padding-left: 1.5rem;
}

.clickable-text {
  cursor: pointer;
  user-select: none;
}

.foot{
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  padding-left: 4rem;
  outline: 1px solid #fff2;
  box-shadow: 0px 5px 6px #0008;
  z-index: 1;
  background-color: #141414;
}

.foot a { 
  transition: all 0.1s ease-in;
}

aside{
  display: block;
  justify-content: left;
  width: 12rem;
  border-right: 1px solid #fff2;
  box-shadow: 5px 0px 6px #0008;
  padding: 0.5rem;
  padding-left: 1rem;
  position: sticky;
  z-index: 0;
  background-color: #141414;
  flex-shrink: 0;
}

.side-container{
  display: block;
}

.side-container p{
  text-align: left;
  padding: 0.5rem;
}

.side-container div {
  border-bottom: 1px solid #fff2;
  transition: all 0.15s ease-in;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 4px;
}

.side-container div:hover {
  background-color: #fff1;
}

.head em{
  letter-spacing: 0.35px;
}

.body{
  width: 70%;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.navbar {
  display: flex;
  max-width: 50%;
  margin: auto;
}

a:link {
  text-decoration: none;
  color: var(--main-color);
}

a:visited {
  text-decoration: none;
  color: var(--main-color);
}

a:hover {
  text-decoration: none;
  color: var(--lilac);
}

a:active {
  text-decoration: none;
  color: var(--active-color);
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.memory-button{
  height: 2.5rem;
  margin: 0.75rem;
  max-width: 12rem;
  min-width: 5rem;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  
  overflow: hidden;
  outline: 1px solid #fff5;
  background-color: #fff1;

  user-select: none;

  text-align: center;
  align-items: center;

  transition: all 0.2s cubic-bezier(0.275, 0.485, 0, 1);
}

.memory-button h2, .memory-button a{
  text-align: center;
  margin: auto;
}

.memory-button h2 sup {
  font-size: 18px;
}

.button{
  width:fit-content;
  height:fit-content;
  margin: auto;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  min-width: 5rem;
 
  padding: 8px 15px 8px 15px;
  
  overflow: hidden;
  outline: 1px solid #fff5;
  background-color: #fff1;

  user-select: none;

  text-align: center;
  align-items: center;

  transition: all 0.2s cubic-bezier(0.275, 0.485, 0, 1);
}

.button:active{
  color: var(--active-color);
}

.button h2, .button a{
  text-align: center;
  margin: auto;
}

.button h2 sup {
  font-size: 18px;
}

.button:hover, .memory-button:hover {
  outline: 2px solid #fff;
  outline-offset: 2px;
  background-color: #fff4;
  cursor: pointer;
}

.search {
  background: #1f2123;
  max-width: 13rem;
  min-width: 13rem;
  align-items: center;
  margin: auto;

  outline: 1px solid #fff5;
  
  transition: all 0.2s cubic-bezier(0.275, 0.485, 0, 1);
}

.search:hover {
  outline: 2px solid #fff;
}

.search input{
  flex: 1;
  background: none;
  color: #f2fffe;
  font-weight: 500;
  font-size: 14px;
  padding: 0.5rem 1rem;
  margin: auto;

  text-align: center;

  border: none;
  outline: none;
}

select {
  flex: 1;
  background: #1f2123;
  outline: 1px solid #fff5;
  color: #f2fffe;
  font-weight: 500;
  font-size: 14px;
  margin: 0.75rem auto;
  padding: 0.5rem 1rem;

  max-width: 15rem;


  transition: all 0.2s cubic-bezier(0.275, 0.485, 0, 1);
}

select:hover{
  outline: 3px solid #fff;
}

.board {
  display: flex;
  flex-wrap: wrap;
  width: 20rem;
  padding: 6px;
  margin: 1rem auto;
  justify-content: center;

  outline: 2px solid #fff;
  border-radius: 4px;
}

.game-tile{
  background-color: #fff3;
  width: 4rem;
  height: 4rem;
  border-radius: 4px;
  float: left;
  margin: 6px 12px;
  margin: 0.4rem;

  display: flex;
  transition: all 0.3s cubic-bezier(0.275, 0.485, 0, 1);
}

.game-tile span{
  font-size: 22px;
  font-weight: 500;
  margin: auto;

  font-family: Comic Sans;

  user-select: none;
}

.game-tile.empty{
  background-color: #fff1;
  z-index: 0;
  position: relative;
}